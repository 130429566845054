import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Content from './components/Content';
import { fetchCockpitData } from './utils/cockpit';

const App = (props) => {

  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [pages, setPages] = useState([]);
  const [ previewItem, setPreviewItem ] = useState({});
  
  useState(() => {
    fetchCockpitData('pages', setPages, true, null, { published: true }, { _o: 1 });
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <Content
      {...props}
      navigate={navigate}
      location={location}
      pathname={location.pathname}
      {...windowDimensions}
      pages={pages}
      setPages={setPages}
      previewItem={previewItem}
      setPreviewItem={setPreviewItem}
    />
  );
}

export default App;
