const PageContentRowHeading = (props) => {

  const { row, defaultColumnClasses, windowWidth } = props;
  const { value } = row;
  const { heading } = value;

  return (
    <div className="row page__row">
      {
        heading && heading !== '' &&
        <h2 className={`col-xxs-12 page__heading--main ${windowWidth >= 500 ? `border-left border-bottom` : ''} ${defaultColumnClasses}`}>
          {heading}
        </h2>
      }
    </div>
  )
}

export default PageContentRowHeading;