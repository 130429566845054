import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Footer from '../components/Footer';
import PageContent from '../components/Page/PageContent';
import IframeOverlay from '../components/Page/IframeOverlay';

const Page = (props) => {

  const { windowWidth, windowHeight, isPreview } = props;
  const [activeIframeOverlay, setActiveIframeOverlay] = useState({});
  const [ defaultColumnClasses ] = useState('col-xxs-12 col-xs-10 col-sm-9 col-md-7 col-lg-6 col-xl-5 page--page__inner');
  
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`page page--page${isPreview === true ? ' page--preview' : ''}`}
    >
      {
        window.location.origin.indexOf('http://localhost:') === 0 &&
        <p>{windowWidth}{' '}({windowWidth < 500 ? 'xxs' : windowWidth < 768 ? 'xs' : windowWidth < 1200 ? 'sm' : windowWidth < 1500 ? 'md' : windowWidth < 1920 ? 'lg' : windowWidth < 2500 ? 'xl' : 'xxl'})</p>
      }
      <div
        className="container-fluid"
        style={{
          minHeight: windowHeight - 96 + 'px',
        }}
      >
        <PageContent
          {...props}
          defaultColumnClasses={defaultColumnClasses}
          setActiveIframeOverlay={setActiveIframeOverlay}
        />
      </div>
      <AnimatePresence>
        {
          activeIframeOverlay.url &&
          <IframeOverlay {...props} value={activeIframeOverlay} setActiveIframeOverlay={setActiveIframeOverlay} />
        }
      </AnimatePresence>
      <Footer {...props} />
    </motion.main>
  )
}

export default Page;