import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './Header';
import Preview from '../utils/Preview';
import Page from '../views/Page';
import Home from '../views/Home';
import P5Sketch from './P5Sketch';

const Content = (props) => {

  const { location, pathname, windowWidth, windowHeight, previewItem, pages } = props;

  return (
    <div
      className={`wrapper`}
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      <Header {...props} />
      <Preview {...props} />
      <P5Sketch {...props} />
      {
        !previewItem.collection &&
        <main
          className="main"
          style={{
            height: windowHeight + 'px',
            width: windowWidth + 'px',
            position: 'relative',
            zIndex: 2
          }}
        >
          <AnimatePresence exitBeforeEnter initial={false}>
            <Routes location={location} key={pathname}>
              {
                pages &&
                pages[0] &&
                pages.map(
                  (page, index) => (
                    <Route
                      key={`page ${index}`}
                      path={`/page/${page.name_slug}`}
                      element={<Page {...props} page={page} />}
                    />
                  )
                )
              }
              <Route
                path="*"
                element={<Home {...props} />}
              />
            </Routes>
          </AnimatePresence>
        </main>
      }
      {
        previewItem.collection === 'pages' &&
        <Page {...props} page={previewItem} isPreview={true} />
      }
    </div>
  )
}

export default Content;