import { useRef, useState } from 'react';
import Sketch from 'react-p5';

const P5Sketch = (props) => {

  const { windowWidth, windowHeight } = props;
  const canvasParentRef = useRef(null);

  const [ image1, setImage1 ] = useState(null);

	const setup = (p5, canvasParentRef) => {
		// use parent to render the canvas in this ref
		// (without that p5 will render the canvas outside of your component)
		p5.createCanvas(windowWidth, windowHeight).parent(canvasParentRef);
    // background(220);
    setImage1(p5.loadImage('/test_tube.png'));
	};

	const draw = (p5) => {
    let number = 2;
    p5.image( image1, p5.mouseX, p5.mouseY, image1.width / number, image1.height / number);
  };


  const windowResized = (p5) => {
    p5.resizeCanvas(window.innerWidth, window.innerHeight);
    console.log('windowResized');
  }


  return (
    <div
      ref={ canvasParentRef }
      style={ {
        width: windowWidth + 'px',
        height: windowHeight + 'px',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
      } }
    >
      <Sketch setup={ setup } draw={ draw } windowResized={ windowResized } />
    </div>
  );
};

export default P5Sketch;