import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

const Header = (props) => {

  const { pages, windowWidth } = props;
  const { pathname } = useLocation();
  const [menuIsActive, setMenuIsActive] = useState(false);

  return (
    <header className="header container-fluid">
      <Link to="/" className="title__link">
        <h1 className='title'>Experimental Code Lab</h1>
      </Link>
      {
        windowWidth < 768 &&
        <button
          className='menu__button menu__link'
          onClick={ () => setMenuIsActive(!menuIsActive) }
        >Menu</button>
      }
      <AnimatePresence>
        {
          (windowWidth >= 768 || menuIsActive === true) &&
          <motion.nav
            initial={ { opacity: 0 } }
            animate={ { opacity: 1 } }
            exit={ { opacity: 0 } }
            transition={ { duration: 0.5 } }
            className='menu'>
            {
              pages &&
              pages?.length > 0 &&
              pages.map((page, index) => (
                page.unlocked === true ?
                  <Link
                    to={ `/page/${ page.name_slug }` }
                    key={ `page ${ index }` }
                    onClick={ () => setMenuIsActive(false) }
                    className={ `menu__link ${ pathname === `/page/${ page.name_slug }` ? 'active' : '' }` }
                  >{ page.name } 🧪</Link>
                  :
                  <span
                    key={ `page ${ index }` }
                    className={ `menu__link locked` }
                  ><span>{ page.name }</span> 🔒</span>
              ))
            }
          </motion.nav>
        }
      </AnimatePresence>
    </header>
  )
}

export default Header;