import { motion } from 'framer-motion';

const Home = (props) => {

  return (
    <motion.div
      initial={ { opacity: 0 } }
      animate={ { opacity: 1 } }
      exit={ { opacity: 0 } }
      style={ {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10vmin'
      } }
    >
      {/* <svg id="uuid-fff29d3a-a78d-49df-a421-bd106cf2fb6b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785.59 409.71">
        <polygon points="481.96 138.61 486.41 38.18 310.85 133.05 240.31 0 234.95 133.11 26.65 86.82 206.3 253.13 0 390.67 333.93 306.96 317.93 409.21 474.19 291.13 533.03 409.71 557.11 248.28 785.59 184.02 481.96 138.61" fill="#fff" />
      </svg> */}
    </motion.div>
  )
};

export default Home;