import { useMemo, useRef } from 'react';

const PageContentRowCode = (props) => {

  const { row, defaultColumnClasses } = props;
  const { value } = row;
  const { code } = value;

  const textarea = useRef(null);
  const button = useRef(null);

  const rows = useMemo(() => {
    if (code) {
      return code.split(/\r?\n/).length;
    } else {
      return 1;
    }
  }, [code]);
  
  console.log(value)

    return (
      <div className="row page__row">
        <div className={defaultColumnClasses}>
          <div className="page__code">
            <button
              className='page__code__button'
              ref={ button }
              onClick={ () => {
                textarea.current.select();
                textarea.current.setSelectionRange(0, 99999); // For mobile devices
                // Copy the text inside the text field
                navigator.clipboard.writeText(textarea.current.value);

                button.current.innerHTML = 'copied ✅';
              } }
            >copy</button>
            <textarea
              ref={ textarea }
              className='page__code__textarea'
              rows={rows}
            >
              {code}
            </textarea>
          </div>
        </div>
      </div>
    );
}

export default PageContentRowCode;